import React, { ReactElement } from 'react'
import { withStore } from '~/dataStore'
import Textarea from '~/components/forms/Textarea/Textarea.field'
import SeparatorLine from '~/components/SeparatorLine'
import CollapseWithToggle from '~/components/CollapseWithToggle'
import useCollapseContext from '~/components/CollapseWithToggle/CollapseWithToggle.context'
import { PushFields } from '~/pages/Campaign/Notification/PushNotification/Model/PushVariant.model'
import AlertNotification from '~/pages/Campaign/Notification/AlertNotification/Model/AlertNotification'
import Goals from '~/pages/Campaign/Notification/NotificationGoals/UI'

interface IProps {
  alertNotification: AlertNotification
  isCardCampaign: boolean
  isSended: boolean
  withGoals?: boolean
}

function AlertVariantStep({
  alertNotification,
  isCardCampaign = false,
  isSended: isSent,
  withGoals = false
}: IProps): ReactElement | null {
  const { currentVariant, currentVariantId, shared } = alertNotification

  if (!currentVariant) {
    return null
  }

  const { opened: openPicker, setOpened: setOpenPicker } =
    useCollapseContext() || {}

  function handleCheck(type: keyof PushFields): (check: boolean) => void {
    return (check: boolean) => {
      if (!currentVariant) {
        return
      }
      if (!isSent) {
        if (!check) {
          currentVariant.reset(type)
        } else {
          currentVariant.setActive(type, true)
        }
        currentVariant.validateField(type)
      }
    }
  }

  const pickerProps = (
    type: keyof PushFields,
    name: string | React.ReactNode | undefined
  ) => ({
    name,
    disabled: isSent,
    opened: openPicker === type,
    isValid: currentVariant.isFieldValid(type),
    setOpened: (open: boolean) => {
      setOpenPicker?.(open ? type : null)
    },
    checked: currentVariant.isFieldActive(type),
    setChecked: handleCheck(type)
  })

  return (
    <>
      <CollapseWithToggle {...pickerProps('title', 'Title')}>
        <Textarea
          key={`${currentVariantId}-title`}
          onChange={currentVariant.title.setValue}
          value={currentVariant.title.value}
          disabled={isSent}
          placeholder="Create a title..."
          mergeTags={shared.mergeTags}
          withoutLineBreaks
          onBlur={() => currentVariant.validateField('title')}
        />
      </CollapseWithToggle>
      <SeparatorLine spaceBottom="0" spaceTop="0" />
      <CollapseWithToggle {...pickerProps('notification', 'Text')} onlyCollapse>
        <Textarea
          key={`${currentVariantId}-notification`}
          onChange={currentVariant.notification.setValue}
          value={currentVariant.notification.value}
          invalid={!currentVariant.notification.isValid}
          required
          withoutLineBreaks
          disabled={isSent}
          onBlur={() => currentVariant.validateField('notification')}
          placeholder="This is your main notification..."
          mergeTags={shared.mergeTags}
        />
        {isCardCampaign ? (
          <p className="text-blue-gray text-13">
            Tapping this notification will show you the Feed Post.
          </p>
        ) : (
          currentVariant.notificationAction && (
            <p className="text-blue-gray text-13">
              Tapping this notification will open an app
            </p>
          )
        )}
        {withGoals && currentVariant.goals && (
          <div className="mb-3">
            <Goals
              disabled={isSent}
              setGoal={currentVariant.goals.setGoal}
              sourceType="notification"
              selected={currentVariant.goals.selectedGoalTypes}
              checkedGoalType={currentVariant.goals.isChecked({
                sourceType: 'notification'
              })}
            />
          </div>
        )}
      </CollapseWithToggle>
    </>
  )
}

export default withStore(AlertVariantStep)
