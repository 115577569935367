import { action, computed, makeObservable, observable } from 'mobx'
import { ALPHABET } from '~/pages/CampaignBuilder/Email/consts'
import { PartialPreview } from '~/pages/Campaign/CampaignReview/CampaignReview.interface'
import {
  ActionTypes,
  CampaignType,
  ICampaignModel,
  IPreviewItem,
  IPushPayload,
  PreviewFieldType
} from '~/dataStore/Campaign/Campaign.interface'
import { getCampaignTypeOptions } from '~/dataStore/CampaignTypeFacade'
import { IRegisteredField } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import StepStore from '~/dataStore/emailBuilder/StepStore'
import AlertVariant from './AlertVariant.model'
import Shared from '~/dataStore/Campaign/Shared'
import { IAppDetails } from '~/dataStore/App.interface'
import { IPushDTO } from '../../Notification.interface'

export default class AlertNotification extends StepStore {
  appId: string

  private isCardCampaign: boolean

  public currentVariantId = 'A'

  public variants: AlertVariant[]

  public shared = new Shared()

  //* temp solution, remove after variants are added to journeys and goals!
  public isCampaignPush: boolean

  constructor({
    app,
    campaign,
    isCardCampaign = false
  }: {
    app?: Pick<IAppDetails, 'id' | 'featureFlags'>
    campaign?: ICampaignModel
    isCardCampaign?: boolean
  }) {
    super()

    makeObservable(this, {
      currentVariantId: observable,
      variants: observable,
      validateStep: action.bound,
      setVariant: action.bound,
      addVariant: action.bound,
      removeVariant: action.bound,
      currentVariant: computed,
      registeredFields: computed
    })

    this.isCampaignPush = !!campaign

    this.isCardCampaign = isCardCampaign

    this.appId = app?.id

    this.setupBlankVariant()
  }

  private setupBlankVariant(): void {
    const variant = new AlertVariant(
      this.currentVariantId,
      this.appId,
      this.isCardCampaign,
      this.isCampaignPush
    )
    variant.notificationAction?.destinationType.setValue(ActionTypes.OPEN_APP)
    this.variants = [variant]
  }

  validateStep(): void {
    this.variants.forEach((variant) => variant.validate())

    this.beenValid = true
  }

  public setVariant(variant: string): void {
    this.currentVariantId = variant
  }

  public async addVariant(type: 'duplicate' | 'blank'): Promise<void> {
    if (this.variants.length === 3) {
      return
      // throw new Error('Can not add new Variant')
    }

    const newVariant = new AlertVariant(
      ALPHABET[this.variants.length].toUpperCase(),
      this.appId,
      this.isCardCampaign,
      this.isCampaignPush
    )

    if (type === 'duplicate') {
      newVariant.fillVariant(await this.variants[0].getDuplicatedPayload())
    }
    this.variants.push(newVariant)
    this.setVariant(newVariant.id)
  }

  public removeVariant(variantId: string): void {
    this.variants = this.variants.filter((v) => v.id !== variantId)
    this.reassignVariantsId()
    this.reassignSelectedVariant()
  }

  private reassignVariantsId(): void {
    this.variants.forEach((v, index) => v.setId(ALPHABET[index].toUpperCase()))
  }

  private reassignSelectedVariant(): void {
    if (!this.variants.some((v) => v.id === this.currentVariantId)) {
      this.setVariant(this.variants[0].id)
    }
  }

  public get currentVariant(): AlertVariant | null {
    const variant = this.variants.find((v) => v.id === this.currentVariantId)
    return variant || null
  }

  public get registeredFields(): IRegisteredField[] {
    return this.variants.flatMap((v) => v.registeredFields)
  }

  public fillStore({
    pushVariants
  }: {
    pushVariants?: (IPushPayload | IPushDTO)[]
  }): void {
    if (!pushVariants) {
      return
    }
    this.variants = pushVariants.map((variant, index) => {
      const newVariant = new AlertVariant(
        ALPHABET[index].toUpperCase(),
        this.appId,
        this.isCardCampaign,
        this.isCampaignPush
      )
      newVariant.fillVariant(variant)

      return newVariant
    })

    this.beenValid = true
  }

  public getPayload(): Promise<(IPushPayload | IPushDTO)[]> {
    return Promise.all(this.variants.map((variant) => variant.getPayload()))
  }

  private generatePreview(variant: AlertVariant): IPreviewItem[] {
    const parts: IPreviewItem[] = []

    if (variant) {
      const { title, notification } = variant

      if (title.isActive) {
        parts.push({
          type: PreviewFieldType.HEADLINE,
          value: title.value || 'Grab your users attention'
        })
      }

      parts.push({
        type: PreviewFieldType.TEXT,
        value:
          notification.value ||
          'Add 1 or 2 extra lines to make your notification tempting and tap!'
      })
    }

    return parts
  }

  public generateCurrentVariantPreview(): PartialPreview {
    return {
      push: {
        variants: [
          {
            parts: this.generatePreview(this.currentVariant),
            goals: this.currentVariant?.goals?.getPayload()
          }
        ]
      },
      type: getCampaignTypeOptions([CampaignType.PUSH], {
        push: { variants: [{ parts: [] }] }
      })
    }
  }

  public generateAllVariantsPreview(): PartialPreview {
    const preview = {
      variants: this.variants.map((variant) => ({
        parts: this.generatePreview(variant)
      }))
    }

    return {
      push: preview,
      type: getCampaignTypeOptions([CampaignType.PUSH], {
        push: preview
      })
    }
  }
}
