import { action, computed, makeObservable, observable } from 'mobx'
import { ActionTypes } from '~/dataStore/Campaign/Campaign.interface'
import { isType } from '~/utils/utilities'
import { hasInvalidBuilderCharacters } from '~/utils/validations'
import {
  IRegisteredField,
  ValidationTypes
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import NewRegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import ToggleRegisteredField, {
  IToggleField
} from '../../../../../dataStore/emailBuilder/Fields/Toggle.field'
import CTAField from '../../../../../dataStore/emailBuilder/Fields/CTA.field'
import CTAGroup from '../../../../../dataStore/emailBuilder/Fields/CTAGroup.field'
import { ID } from '~/common.interface'
import { NotificationType, IAlertDTO } from '../../Notification.interface'
import NotificationGoals from '../../NotificationGoals'

export type AlertFields = {
  title: IToggleField
  notification: IRegisteredField
}

export default class AlertVariant {
  public notificationId: ID | undefined = undefined

  public toggleField = new NewRegisteredField<boolean>(true)

  public id: string

  public title: IToggleField = new ToggleRegisteredField<string>('')

  public notification = new NewRegisteredField<string>('')

  public notificationAction: CTAField | undefined

  public goals: NotificationGoals | undefined

  constructor(
    variant: string,
    public appId: string,
    public isWithCard: boolean = false,
    public isSingleCampaign = true
  ) {
    makeObservable<AlertVariant, 'validateInvalidCharacters'>(this, {
      id: observable,
      title: observable,
      notification: observable,
      notificationAction: observable,
      setId: action.bound,
      validateInvalidCharacters: action.bound,
      validate: action.bound,
      registeredFields: computed
    })

    this.id = variant
    //* temp solution until variants are added to journeys and goals to campaigns
    let prefix = ''

    this.notificationAction = new CTAField()
    this.notificationAction.destinationType.setValue(ActionTypes.OPEN_APP)

    if (isSingleCampaign) {
      prefix = `Variant ${this.id}: `
    } else {
      this.goals = new NotificationGoals()
    }

    this.title.isActive = false

    this.notification.setErrorMessage(
      `${prefix}You must add your notification text`
    )

    if (!this.isWithCard) {
      this.notificationAction = new CTAField(
        `${isSingleCampaign ? `Variant ${this.id} ` : ''}notification action: `
      )
      this.notificationAction.withLabel = false
    }

    this.reset = this.reset.bind(this)
    this.setActive = this.setActive.bind(this)
    this.fillVariant = this.fillVariant.bind(this)
  }

  public setId(id: string): void {
    this.id = id
  }

  public validateField(type: keyof AlertFields): void {
    const field = this[type]
    field.validate()

    if (
      field instanceof NewRegisteredField &&
      isType<'title' | 'notification'>(type, (val) =>
        ['title', 'notification'].includes(val as string)
      )
    ) {
      this.validateInvalidCharacters(field, type)
    }
  }

  private validateInvalidCharacters(
    field: IToggleField | NewRegisteredField,
    fieldName: 'title' | 'notification'
  ): boolean {
    const parsedName = {
      title: 'Title',
      notification: 'Notification Text'
    }

    const isActive = 'isActive' in field ? field.isActive : true

    if (!isActive) return true

    if (field.isValid && hasInvalidBuilderCharacters(field.value)) {
      const prefix = this.isSingleCampaign ? `Variant ${this.id} ` : ''
      field.setError(
        ValidationTypes.FORMAT,
        `${prefix}${parsedName[fieldName]}: The following characters are not allowed: '<', '>', '[', ']'.`
      )
    }

    return field.isValid
  }

  public validate(): void {
    this.title.validate()
    this.validateInvalidCharacters(this.title, 'title')
    this.validateInvalidCharacters(this.notification, 'notification')
    this.notificationAction?.validate()
    this.goals?.validate([{ sourceType: 'button', active: false }])
  }

  public get registeredFields(): IRegisteredField[] {
    return [
      this.toggleField,
      this.title,
      this.notification,
      this.notificationAction?.destinationType,
      this.goals
    ].filter(Boolean) as IRegisteredField[]
  }

  // eslint-disable-next-line class-methods-use-this
  private isToggleField(
    field: IToggleField | NewRegisteredField<string> | CTAField | CTAGroup
  ): field is IToggleField {
    return 'isActive' in field
  }

  public isFieldValid(fieldName: keyof AlertFields): boolean {
    const field = this[fieldName]

    let goalsValid = true
    if (this.goals) {
      goalsValid = this.goals.isValid
    }

    if (fieldName === 'notification' && this.notificationAction) {
      return field.isValid && this.notificationAction.isValid
    }

    return field.isValid
  }

  public isFieldActive(fieldName: keyof AlertFields): boolean {
    const field = this[fieldName]

    if (this.isToggleField(field)) {
      return field.isActive
    }

    return true
  }

  public reset(fieldName: keyof AlertFields): void {
    this.setActive(fieldName, false)
  }

  public setActive(fieldName: keyof AlertFields, value: boolean): void {
    const field = this[fieldName]
    if (this.isToggleField(field)) {
      this.toggleField.setValue(!this.toggleField.value)
      field.setActive(value)
    }
  }

  public async getDuplicatedPayload(): Promise<IAlertDTO> {
    const payload = await this.getPayload(true)

    return {
      ...payload
    }
  }

  public async getPayload(duplicate = false): Promise<IAlertDTO> {
    if (this.isSingleCampaign) {
      return {
        id: duplicate ? undefined : this.notificationId,
        bodyTemplate: this.notification.value,
        ...this.notificationAction?.payload,
        ...(this.title.isActive && { titleTemplate: this.title.value }),
        activeFields: {
          titleTemplate: this.title.isActive
        }
      }
    }
    return {
      id: duplicate ? undefined : this.notificationId,
      type: NotificationType.ALERT,
      goals: this.goals?.getPayload() || [],
      frontParts: {
        title: {
          text: this.title.value,
          position: 0,
          active: this.title.isActive,
          side: 'front'
        },
        pushBody: {
          text: this.notification.value,
          position: 2,
          active: true,
          side: 'front',
          ...this.notificationAction?.payload
        }
      }
    }
  }

  public fillVariant(variant: IAlertDTO): void {
    if (!variant.template) {
      this.notificationId = variant.id
    }
    if ('frontParts' in variant) {
      this.fillNotificationPayload(variant)
    } else {
      this.fillCampaignPayload(variant)
    }
  }

  private fillCampaignPayload(payload: IAlertDTO): void {
    this.notification.fillField({ value: payload.bodyTemplate })
    this.title.fillField({
      value: payload.titleTemplate,
      isActive: !!payload.activeFields?.titleTemplate
    })
    this.notificationAction?.fillField(payload)
  }

  private fillNotificationPayload(payload: IAlertDTO): void {
    const { frontParts } = payload
    this.notification.fillField({ value: frontParts.pushBody.text })
    this.title.fillField({
      value: frontParts.title.text,
      isActive: !!frontParts.title.active
    })

    if (this.goals && payload.goals) {
      this.goals.fillGoals(payload.goals)
    }

    this.notificationAction?.fillField(frontParts.pushBody)
  }
}
