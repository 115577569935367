import { matchPath } from 'react-router-dom'
import { IFeatureFlags } from './dataStore/App.interface'

export type RoutesKey =
  | 'appDashboard'
  | 'dashboard'
  | 'appSettings'
  | 'appChannelSettings'
  | 'devicesDashboard'
  | 'campaignDashboard'
  | 'campaignReports'
  | 'campaignReport'
  | 'campaignDetails'
  | 'campaignList'
  | 'segmentsList'
  | 'segmentsBuilder'
  | 'userList'
  | 'userProfile'
  | 'segmentUsersList'
  | 'beaconsList'
  | 'geofences'
  | 'newCampaign'
  | 'newApp'
  | 'campaignBuilder'
  | 'journeys'
  | 'messages'
  | 'feed'

export type SubRoutesKey =
  | 'journeysBuilder'
  | 'journeysNew'
  | 'journeysReport'
  | 'newMessage'

export type Route = PublicRoute | PrivateRoute | SubRoute

export type PublicRoute = { path: string; subRoute?: never; flag?: never }
export type PrivateRoute = {
  path: string
  flag: keyof IFeatureFlags
  subRoute?: never
  withSubRoute?: boolean
}
export type SubRoute = { path: string; subRoute: boolean; flag?: never }

const routes: {
  [key in RoutesKey | SubRoutesKey]: Route
} = {
  appDashboard: {
    path: '/mobile/apps/:appId/dashboards'
  },
  dashboard: {
    path: '/mobile/apps/:appId/dashboard_beta',
    flag: 'dashboardBeta'
  },
  appChannelSettings: {
    path: '/mobile/apps/:appId/channel_settings'
  },
  appSettings: {
    path: '/mobile/apps/:appId/app_settings'
  },
  devicesDashboard: {
    path: '/mobile/apps/:appId/dashboards/devices'
  },
  campaignDashboard: {
    path: '/mobile/apps/:appId/dashboards/:campaignType(email)',
    flag: 'email'
  },
  campaignReports: {
    path: '/mobile/apps/:appId/reports'
  },
  campaignReport: {
    path: '/mobile/apps/:appId/reports/:campaignId'
  },
  campaignDetails: {
    path: '/mobile/apps/:appId/campaigns/:campaignId/details'
  },
  campaignList: {
    path: '/mobile/apps/:appId/campaigns'
  },
  segmentsList: {
    path: '/mobile/apps/:appId/segments'
  },
  segmentsBuilder: {
    path: '/mobile/apps/:appId/segments/builder/:segmentId?'
  },
  segmentUsersList: {
    path: '/mobile/apps/:appId/segments/:segmentId'
  },
  userList: {
    path: '/mobile/apps/:appId/users'
  },
  userProfile: {
    path: '/mobile/apps/:appId/users/:userId'
  },
  beaconsList: {
    path: '/mobile/apps/:appId/beacons'
  },
  geofences: {
    path: '/mobile/apps/:appId/geofences'
  },
  newCampaign: {
    path: '/mobile/apps/:appId/campaigns/new_builder'
  },
  newApp: {
    path: '/mobile/apps/new'
  },
  campaignBuilder: {
    path: '/mobile/apps/:appId/campaigns/new_builder/:campaignType/:notificationType(push|in_app|sms|alert)?/:size(large|small)?/:campaignId?'
  },
  journeys: {
    path: '/mobile/apps/:appId/journeys',
    withSubRoute: true
  },
  journeysBuilder: {
    path: '/mobile/apps/:appId/journeys/:mode(builder|details)/:journeyId?',
    subRoute: true
  },
  journeysNew: {
    path: '/mobile/apps/:appId/journeys/new',
    subRoute: true
  },
  journeysReport: {
    path: '/mobile/apps/:appId/journeys/reports/:journeyId',
    subRoute: true
  },
  messages: {
    path: '/mobile/apps/:appId/talks',
    flag: 'messages'
  },
  newMessage: {
    path: '/mobile/apps/:appId/talks/new',
    subRoute: true
  },
  feed: {
    path: '/mobile/apps/:appId/feed'
  }
}

export default routes

export function getParams(
  route: keyof typeof routes
): Record<string, any> | null {
  const match = matchPath(window.location.pathname, {
    path: routes[route].path
  })

  return match?.params || null
}
