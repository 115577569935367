import { Edge, Node } from 'reactflow'
import { Dictionary } from 'lodash'
import InAppNotification from '~/pages/Campaign/Notification/InAppNotification/Model/InAppNotification'
import { GoalType } from '~/pages/Campaign/Notification/NotificationGoals/NotificationGoals.interface'
import PushNotification from '~/pages/Campaign/Notification/PushNotification/Model/PushNotification'
import {
  JourneyErrors,
  JourneyStatus
} from '~/pages/Journeys/Journeys.interface'
import { Date, ID, TimeUnit } from '~/common.interface'
import JourneyEmailNotificationStore from '../JourneyNotificationSidebar/EmailNotificationSidebar/Store/JourneyEmailNotificationStore'
import {
  IRegisteredField,
  Trigger
} from '~/dataStore/emailBuilder/EmailBuilder.interface'
import { NotificationType } from '~/pages/Campaign/Notification/Notification.interface'
import JourneyCard from '../JourneyNotificationSidebar/CardNotificationSidebar/JourneyCard'
import { DELIVERY } from '~/pages/CampaignBuilder/Email/consts'
import AlertNotification from '~/pages/Campaign/Notification/AlertNotification/Model/AlertNotification'

// #region ENUMS

export const journeyBlocksType = [
  'start',
  'message',
  'reachable',
  'split',
  'addToSegment',
  'delay',
  'exit'
] as const

export type JourneyTypeAsKey = (typeof journeyBlocksType)[number]

export enum JourneyBlockType {
  START = 'start',
  MESSAGE = 'message',
  SPLIT = 'split',
  ADDTOSEGMENT = 'add_to_segment',
  DELAY = 'delay',
  REDIRECT = 'redirect',
  EXIT = 'exit'
}

export enum JourneyBlockTitle {
  START = 'Start',
  MESSAGE = 'Message',
  SPLIT = 'Split',
  ADDTOSEGMENT = 'Add to segment',
  DELAY = 'Delay',
  EXIT = 'Exit'
}

export const BlockTypeToBlockTitle = new Map<
  JourneyBlockType,
  JourneyBlockTitle
>([
  [JourneyBlockType.START, JourneyBlockTitle.START],
  [JourneyBlockType.MESSAGE, JourneyBlockTitle.MESSAGE],
  [JourneyBlockType.DELAY, JourneyBlockTitle.DELAY],
  [JourneyBlockType.SPLIT, JourneyBlockTitle.SPLIT],
  [JourneyBlockType.ADDTOSEGMENT, JourneyBlockTitle.ADDTOSEGMENT],
  [JourneyBlockType.EXIT, JourneyBlockTitle.EXIT]
])

export enum NodeType {
  ENTRY = 'entry',
  DELAY = 'delay',
  SPLIT = 'split',
  MESSAGE = 'message',
  ADDTOSEGMENT = 'addtosegment',
  REDIRECT = 'redirect',
  EXIT = 'exit'
}

export enum NodeState {
  FILLED = 'filled',
  FILLED_NOTIFICATION = 'filled-notification',
  ERROR = 'error'
}

export enum SplitSubType {
  REACHABLE = 'reachable',
  GOALABLE = 'goalable',
  INTEGRATIONABLE = 'integrationable'
}

// #endregion

// #region Dictionary

export const BlockTypeToNodeType = new Map<
  JourneyBlockType | SplitSubType,
  NodeType
>([
  [JourneyBlockType.START, NodeType.ENTRY],
  [JourneyBlockType.MESSAGE, NodeType.MESSAGE],
  [SplitSubType.REACHABLE, NodeType.SPLIT],
  [SplitSubType.GOALABLE, NodeType.SPLIT],
  [JourneyBlockType.SPLIT, NodeType.SPLIT],
  [JourneyBlockType.ADDTOSEGMENT, NodeType.ADDTOSEGMENT],
  [JourneyBlockType.DELAY, NodeType.DELAY],
  [JourneyBlockType.REDIRECT, NodeType.REDIRECT],
  [JourneyBlockType.EXIT, NodeType.EXIT]
])

// #endregion

// #region Templates
export type Path = 'Yes' | 'No' | 'Dismiss'

export type SplitActionType = GoalType | 'dismiss'

export interface JourneyTemplatesDTO {
  data: JourneyTemplate[]
  metadata: {
    page: number
    perPage: number
    totalPages: number
    dataCount: number
  }
}

export interface JourneyTemplate {
  id: string
  name: string
  description: string
  largeImage: string
  smallImage: string
  diagram: IJourneyTemplateBlock[]
}

export interface IJourneyTemplateBlock {
  id?: ID
  type: JourneyBlockType
  path?: Path
  options?: unknown[]
  childBlocks?: IJourneyTemplateBlock[]
}

// #endregion

// #region DTO

//* JourneyDTO === start block

export type JourneyDTO = {
  id?: ID
  templateId?: ID
  type?: never
  status?: JourneyStatus
  description?: string
  delivery: (typeof DELIVERY)[keyof typeof DELIVERY] | null
  name: string
  startAt?: Date | null
  endAt: Date | null
  segments?: string[]
  beacons?: string[]
  geofences?: string[]
  beaconEvents?: Dictionary<Trigger | null | undefined>
  geofenceEvents?: Dictionary<Trigger | null | undefined>
  geofenceDwellingTimes?: Dictionary<string | number | undefined>
  timeZoneName?: string
  timeZoneOffset?: string
  childBlocks: Exclude<JourneyBlock, JourneyDTO>[]
}

export type JourneyBlock =
  | JourneyDTO
  | ISplitBlock
  | IBlockAddToSegment
  | IBlockMessage
  | IBlockDelay
  | IBlockRedirect
  | IBlockExit

export type NotificationModel =
  | PushNotification
  | AlertNotification
  | JourneyEmailNotificationStore
  | InAppNotification
  | JourneyCard

export interface INotification {
  id: ID
  notifications: {
    id: ID | null
    templateId?: ID
    templateType?: 'global' | 'personal'
    position: number
  }[]
  notification?: NotificationModel
  isValid: boolean
  type: NotificationType | undefined
  position: number
}

export interface IBlockMessage {
  id?: ID
  type: JourneyBlockType.MESSAGE
  name: string
  options: Pick<INotification, 'notifications' | 'type' | 'position'>[]
  redirectReferences?: {
    fromBlockId?: ID | undefined
    toBlockId?: ID | undefined
    guid?: string
  }[]
  childBlocks: JourneyBlock[]
}
export interface ISplitBlock {
  id?: ID
  type: JourneyBlockType.SPLIT
  subType: SplitSubType | undefined
  timeFrame: TimeUnit
  timeValue: number
  options: { type: Lowercase<Path>; childBlockId: ID }[]
  conditions: Array<
    | { messageId: ID; action: SplitActionType }
    | {
        action: SplitActionType
        resourceId: ID
        resourceType: 'message'
      }
    | {
        action: ID
        resourceId: ID
        resourceType: 'journey'
      }
  >
  childBlocks: JourneyBlock[]
}

export interface IBlockRedirect {
  id?: ID
  type: JourneyBlockType.REDIRECT
  name: string
  redirectReferences?: {
    fromBlockId?: ID | undefined
    toBlockId?: ID | undefined
    guid?: string
  }[]
  redirectToBlockId: ID
  childBlocks: JourneyBlock[]
}

export interface IBlockAddToSegment {
  id?: ID
  type: JourneyBlockType.ADDTOSEGMENT
  path?: Path
  name: string
  segmentId: ID
  redirectReferences?: {
    fromBlockId?: ID | undefined
    toBlockId?: ID | undefined
    guid?: string
  }[]
  childBlocks: JourneyBlock[]
}

export interface IBlockDelay {
  id?: ID
  type: JourneyBlockType.DELAY
  timeValue: number
  timeFrame: TimeUnit
  redirectReferences?: {
    fromBlockId?: ID | undefined
    toBlockId?: ID | undefined
    guid?: string
  }[]
  childBlocks: JourneyBlock[]
}

export interface IBlockExit {
  id?: ID
  type: JourneyBlockType.EXIT
}

// #endregion

// #region Models
export interface IBlock {
  readonly id: ID
  blockID: ID | undefined
  node: INodeModel
  children: IBlock[]
  childrenNodes: IBlock[]
  blockType: JourneyBlockType
  parent: IBlock | null
  isValid: boolean
  errors: string[]
  launchErrors: string[]
  path?: Path
  setParent: (parent: IBlock) => void
  setChildren: (chidlren: IBlock[]) => void
  getNode: () => Node
  getPayload: () => JourneyBlock
  setActive: (value: boolean) => void
  isActive: () => boolean
  restore: () => void
  fillBlock(data: JourneyBlock): void
  validateBlock: () => boolean
  setServerErrors: (errors: JourneyErrors) => void
  save: (appId: ID, journeyId: ID | undefined) => Promise<JourneyBlock>
  remove: (appId: ID, journeyId: ID | undefined) => Promise<unknown>
  removeFromTree: () => void
  replaceInParentWith: (block: IBlock) => void
  replaceOptionChildId?: (oldId: ID, newId: ID) => void
  replaceWithFirstChild: () => void
  redirectTo: IRegisteredField
  setPath(path: Path): void
  notify?: (...args: unknown[]) => void
  ready: boolean
}

export interface INodeModel {
  id: ID
  column: number
  level: number
  cx: number
  last: boolean
  blockType: JourneyBlockType
  nodeType: NodeType | undefined
  calculatePosition: (children: { node: INodeModel }[]) => void
  setColumn: (value: number) => void
  setLevel: (value: number) => void
  getNode: () => {
    type: NodeType | undefined
    position: { x: number; y: number }
  }
}
// #endregion

export type NodeWithData<T = IBlock> = Node<NodeData<T>>

export type EdgeWithData = Edge<{
  last: boolean
  label: string | undefined
  createBlock?: (blockType: JourneyBlockType) => unknown
  isisBeforeExit: boolean
}>

export type NodeData<T = IBlock> = {
  block: T
  label: string
  goalType?: GoalType
  subLabel?: string
  multiLabel?: { header: string; value: string }[]
  filled?: boolean
  openPanel?: (nodeId: ID) => void
}
